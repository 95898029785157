<script>
export default {
  props: {
    contacts: {
      type: Array,
      required: true
    },
  },
  data: () => ({
    searchQuery: null,
    page: 1,
    perPage: 6,
    pages: [],
  }),
  computed: {
    displayedContacts() {
      return this.paginate(this.contacts);
    },
    resultQuery() {
      if (this.searchQuery) {
        const search = this.searchQuery.toLowerCase();
        return this.displayedContacts.filter((data) => {
          return (
              data?.contact?.nombre.toLowerCase().includes(search) ||
              data?.contact?.apellido_paterno.toLowerCase().includes(search) ||
              data?.contact?.apellido_materno.toLowerCase().includes(search) ||
              data?.contact?.email.toLowerCase().includes(search) ||
              data?.contact?.area.toLowerCase().includes(search) ||
              data?.contact?.telefono.toLowerCase().includes(search)
          );
        });
      } else {
        return this.displayedContacts;
      }
    },
  },
  watch: {
    contacts() {
      this.setPages();
    },
  },
  created() {
    this.setPages();
  },
  methods: {
    setPages() {
      let numberOfPages = Math.ceil(this.contacts?.length / this.perPage);
      this.pages = [];
      for (let index = 1; index <= numberOfPages; index++) {
        this.pages.push(index);
      }
    },
    paginate(contacts) {
      let page = this.page;
      let perPage = this.perPage;
      let from = page * perPage - perPage;
      let to = page * perPage;
      return contacts?.slice(from, to);
    }
  },
};

</script>

<template>
  <b-row>
    <b-col lg="12">
      <b-card no-body>
        <b-card-header>
          <b-row class="g-4 align-items-center">
            <b-col sm="auto">
              <div>
                <b-card-title class="mb-0 flex-grow-1">Contactos que hacen uso de la aplicación</b-card-title>
              </div>
            </b-col>
            <b-col sm>
              <div class="d-flex justify-content-sm-end">
                <div class="search-box ms-2">
                  <input type="text" class="form-control" id="searchResultList"
                         placeholder="Buscar..." v-model="searchQuery">
                  <i class="ri-search-line search-icon"></i>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-card-header>
        <b-card-body>
          <div id="recomended-jobs" class="table-card">
            <div role="complementary" class="gridjs gridjs-container" style="width: 100%;">
              <div class="gridjs-wrapper" style="height: auto;">
                <table role="grid" class="gridjs-table" style="height: auto;">
                  <thead class="gridjs-thead">
                  <tr class="gridjs-tr">
                    <th data-column-id="position" class="gridjs-th gridjs-th-sort" tabindex="0">
                      <div class="gridjs-th-content">Nombre</div>
                    </th>
                    <th data-column-id="companyName" class="gridjs-th gridjs-th-sort"
                        tabindex="0">
                      <div class="gridjs-th-content">Email</div>
                    </th>
                    <th data-column-id="location" class="gridjs-th gridjs-th-sort" tabindex="0">
                      <div class="gridjs-th-content">Dispositivo</div>
                    </th>
                    <th data-column-id="salary" class="gridjs-th gridjs-th-sort" tabindex="0">
                      <div class="gridjs-th-content">Versión Dispositivo</div>
                    </th>
                    <th data-column-id="experience" class="gridjs-th gridjs-th-sort"
                        tabindex="0">
                      <div class="gridjs-th-content">Versión Aplicación</div>
                    </th>
                  </tr>
                  </thead>
                  <tbody class="gridjs-tbody">
                  <tr class="gridjs-tr" v-for="(data, index) of resultQuery" :key="index">
                    <td data-column-id="position" class="gridjs-td">{{ data.contact?.nombre }} {{ data.contact?.apellido_paterno }}</td>
                    <td data-column-id="companyName" class="gridjs-td">{{ data.contact?.email }}</td>
                    <td data-column-id="location" class="gridjs-td">{{ data.operating_system }}</td>
                    <td data-column-id="salary" class="gridjs-td">{{ data.system_version }}</td>
                    <td data-column-id="experience" class="gridjs-td">{{ data.app_version }}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
              <div class="gridjs-footer">
                <div class="gridjs-pagination">
                  <div role="status" aria-live="polite" class="gridjs-summary" title="Page 1 of 3">
                    Mostrando <b>{{ page }}</b> de
                    <b>{{ resultQuery?.length }}</b> de
                    <b>{{ contacts?.length }}</b>
                    resultado
                  </div>
                  <div class="gridjs-pages">
                    <button tabindex="0" role="button" title="Previous" @click="page--"
                            v-if="page != 1">Anterior
                    </button>

                    <button tabindex="0" role="button" class="gridjs"
                            :class="{ active: pageNumber == page, disabled: pageNumber == '...', }"
                            v-for="(pageNumber, index) in pages" :key="index"
                            @click="page = pageNumber">{{ pageNumber }}
                    </button>

                    <button tabindex="0" role="button" title="Next" @click="page++"
                            v-if="page < pages.length">Siguiente
                    </button>
                  </div>
                </div>
              </div>
              <div id="gridjs-temp" class="gridjs-temp"></div>
            </div>
          </div>
        </b-card-body>
      </b-card>
    </b-col>
  </b-row>
</template>
