<script>
import {defineComponent} from 'vue';

export default defineComponent({
  name: "TotalContacts",
  props: {
    total: {
      type: Number,
      required: true
    },
  }
});
</script>

<template>
  <b-card no-body class="card-height-100">
    <b-card-header class="align-items-center d-flex">
      <b-card-title class="mb-0 flex-grow-1">Total Contactos</b-card-title>
    </b-card-header>

    <b-card-body>

      <div class="table-responsive mt-3">
        <table class="table table-borderless table-sm table-centered align-middle table-nowrap mb-0">
          <tbody class="border-0">
          <tr>
            <td>
              <h4 class="text-truncate fs-14 fs-medium mb-0">
                <i class="ri-stop-fill align-middle fs-18 text-primary me-2"></i>Contactos Registrados
              </h4>
            </td>
            <td>
              <p class="text-muted mb-0">
                <UsersIcon class="me-2 icon-sm"></UsersIcon> {{ total }}
              </p>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </b-card-body>
  </b-card>
</template>

