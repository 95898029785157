<script>
import {defineComponent} from 'vue';

export default defineComponent({
  name: "Chart",
  props: {
    chart: {
      type: Object,
      required: true
    },
  },
  data: () => ({
    chartOptions: {
      labels: ["iOS", "Android"],
      chart: {
        type: "donut",
        height: 219,
      },
      plotOptions: {
        pie: {
          size: 100,
          donut: {
            size: "76%",
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false,
        position: "bottom",
        horizontalAlign: "center",
        offsetX: 0,
        offsetY: 0,
        markers: {
          width: 20,
          height: 6,
          radius: 2,
        },
        itemMargin: {
          horizontal: 12,
          vertical: 0,
        },
      },
      stroke: {
        width: 0,
      },
      yaxis: {
        labels: {
          formatter: function (value) {
            return value + "%" + " Contactos";
          },
        },
        tickAmount: 4,
        min: 0,
      },
      colors: getChartColorsArray('["--vz-primary", "--vz-warning", "--vz-info"]'),
    },
  }),
  computed: {
    series() {
      return [
        this.chart?.iOS ?? 0,
        this.chart?.android ?? 0,
      ];
    }
  }

});

function getChartColorsArray(colors) {
  colors = JSON.parse(colors);
  return colors.map(function (value) {
    var newValue = value.replace(" ", "");
    if (newValue.indexOf(",") === -1) {
      var color = getComputedStyle(document.documentElement).getPropertyValue(newValue);
      if (color) {
        color = color.replace(" ", "");
        return color;
      } else return newValue;
    } else {
      var val = value.split(',');
      if (val.length == 2) {
        var rgbaColor = getComputedStyle(document.documentElement).getPropertyValue(val[0]);
        rgbaColor = "rgba(" + rgbaColor + "," + val[1] + ")";
        return rgbaColor;
      } else {
        return newValue;
      }
    }
  });
}
</script>

<template>
  <b-card no-body class="card-height-100">
    <b-card-header class="align-items-center d-flex">
      <b-card-title class="mb-0 flex-grow-1">Uso por Sistema Operativo</b-card-title>
    </b-card-header>
    <b-card-body>
      <apexchart class="apex-charts" dir="ltr" height="219" :series="series" :options="chartOptions"></apexchart>

      <div class="table-responsive mt-3">
        <table class="table table-borderless table-sm table-centered align-middle table-nowrap mb-0">
          <tbody class="border-0">
          <tr>
            <td>
              <h4 class="text-truncate fs-14 fs-medium mb-0">
                <i class="ri-stop-fill align-middle fs-18 text-primary me-2"></i>iOS
              </h4>
            </td>
          </tr>
          <tr>
            <td>
              <h4 class="text-truncate fs-14 fs-medium mb-0">
                <i class="ri-stop-fill align-middle fs-18 text-warning me-2"></i>Android
              </h4>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </b-card-body>
  </b-card>
</template>

