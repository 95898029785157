<script>
import {defineComponent} from 'vue';
import appConfig from "../../../../app.config.json";
import PageHeader from "@/components/page-header.vue";
import Layout from "@/layouts/main.vue";
import TotalContacts from "./totalContacts.vue";
import Chart from "./chart.vue";
import Devices from "./devices.vue";
import Contacts from "./contacts.vue";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import {has, values} from "lodash";
import formatGraphQLErrors from "@/errors/formatGraphQLErrors";
import {notification} from "@/helpers/notifications";
import {DASHBOARD_APPS} from "@/graphql/queries/dashboard/apps";
import {useVuelidate} from "@vuelidate/core";
import {helpers, required} from "@vuelidate/validators";

export default defineComponent({
  name: "DashboardApps",
  components: {
    Layout,
    PageHeader,
    flatPickr,
    TotalContacts,
    Chart,
    Devices,
    Contacts
  },
  page: {
    title: "Analíticas Apps",
    meta: [{name: "description", content: appConfig.description}],
  },
  data: () => ({
    title: "Analíticas",
    dashboardMessages: null,
    items: [
      {
        text: "Dashboards",
        href: "/",
      },
      {
        text: "Analíticas Apps",
        active: true,
      },
    ],
    defaultDateConfig: {
      dateFormat: "Y-m-d",
      defaultDate: null,
    },
    submitted: false,
    form: {
      startDate: null,
      endDate: null,
    },
    dashboardAnalytics: [],

  }),
  setup: () => ({v$: useVuelidate()}),
  validations() {
    return {
      form: {
        startDate: {
          required: helpers.withMessage("La fecha de inicio es requerida", required),
        },
        endDate: {
          required: helpers.withMessage("La fecha final es requerida", required),
        },
      },
    }
  },
  methods: {
    async generateAnalytics() {
      try {
        this.submitted = true;
        this.v$.$touch();

        if (this.v$.$invalid) {
          return false;
        }

        const {data} = await this.$apollo.query({
          query: DASHBOARD_APPS,
          fetchPolicy: "network-only",
          variables: {
            input: {
              startDate: `${this.form.startDate} 00:00:00`,
              endDate: `${this.form.endDate} 23:59:00`,
            }
          }
        });

        this.dashboardAnalytics = data.dashboardAnalytics;

      } catch (errors) {
        let dataError = has(errors, 'graphQLErrors') ? formatGraphQLErrors(errors.graphQLErrors) : errors.message

        if (has(dataError, 'category') && dataError.category === 'validation') {
          dataError = values(dataError.errors);
        }

        if (has(dataError, 'category') && dataError.category === 'user') {
          dataError = dataError.message
        }

        notification('error', dataError);
      }
    }
  },
  computed: {
    chart() {
      return this.dashboardAnalytics?.chart;
    },
    amountPerUse() {
      return this.dashboardAnalytics?.amountPerUse;
    },
    contacts() {
      return this.dashboardAnalytics?.tableUseApplication;
    },
    totalContacts() {
      return this.contacts?.length;
    },
  }
});
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items"/>

    <b-row>
      <b-card no-body class="card-height-100 mx-2">
        <form
            autocomplete="off"
            class="needs-validation"
            @submit.prevent="generateAnalytics"
        >
          <b-card-body class="row">
            <b-col xxl="4">
              <div class="mt-3">
                <label class="form-label mb-0">Fecha Inicio</label>
                <flat-pickr
                    v-model="form.startDate"
                    :config="defaultDateConfig"
                    class="form-control"
                    :class="{
                      'is-invalid': submitted && v$.form.startDate.$error,
                    }"
                >
                </flat-pickr>
                <div v-for="(item, index) in v$.form.startDate.$errors" :key="index" class="invalid-feedback">
                  <span v-if="item.$message">{{ item.$message }}</span>
                </div>
              </div>
            </b-col>

            <b-col xxl="4">
              <div class="mt-3">
                <label class="form-label mb-0">Fecha Final</label>
                <flat-pickr
                    v-model="form.endDate"
                    :config="defaultDateConfig"
                    class="form-control"
                    :class="{
                      'is-invalid': submitted && v$.form.endDate.$error,
                    }"
                >
                </flat-pickr>
                <div v-for="(item, index) in v$.form.endDate.$errors" :key="index" class="invalid-feedback">
                  <span v-if="item.$message">{{ item.$message }}</span>
                </div>
              </div>
            </b-col>

            <b-col xxl="4">
              <div class="mt-3 justify-content-end">
                <label class="form-label mb-0"></label>
                <b-button
                    class="d-flex"
                    type="submit"
                    variant="customRed"
                >
                  Aceptar
                </b-button>
              </div>
            </b-col>
          </b-card-body>
        </form>
      </b-card>
    </b-row>


    <b-row>
      <b-card no-body class="card-height-100">
        <b-card-body class="row">
          <b-col xxl="6">
            <b-row>
              <b-col xxl="12">
                <TotalContacts :total="totalContacts"/>
              </b-col>

              <b-col xxl="12">
                <Chart :chart="chart"/>
              </b-col>

              <b-col xxl="12">
                <Devices :devices="amountPerUse"/>
              </b-col>
            </b-row>
          </b-col>

          <b-col xxl="6">
            <Contacts :contacts="contacts"/>
          </b-col>
        </b-card-body>
      </b-card>
    </b-row>

  </Layout>
</template>


