<script>
import {defineComponent} from 'vue';

export default defineComponent({
name: "Devices",
  props: {
    devices: {
      type: Object,
      required: true
    },
  },
  data: () => ({}),
});
</script>

<template>
  <b-card no-body class="card-height-100">
    <b-card-header class="align-items-center d-flex">
      <b-card-title class="mb-0 flex-grow-1">Cantidad por Uso de Dispositivo</b-card-title>
    </b-card-header>

    <b-card-body>

      <div class="table-responsive mt-3">
        <table class="table table-borderless table-sm table-centered align-middle table-nowrap mb-0">
          <tbody class="border-0">
          <tr>
            <td>
              <h4 class="text-truncate fs-14 fs-medium mb-0">
                Uso por Sistema Operativo iOS
              </h4>
            </td>
            <td>
              <p class="text-muted mb-0"> {{ devices?.totalUseIos ?? 0 }} </p>
            </td>
          </tr>
          <tr>
            <td>
              <h4 class="text-truncate fs-14 fs-medium mb-0">
                Uso por Sistema Operativo Android
              </h4>
            </td>
            <td>
              <p class="text-muted mb-0"> {{ devices?.totalUseAndroid ?? 0 }} </p>
            </td>
          </tr>
          <tr>
            <td>
              <h4 class="text-truncate fs-14 fs-medium mb-0">
                Primeras Descargas Sistema Operativo iOS
              </h4>
            </td>
            <td>
              <p class="text-muted mb-0"> {{ devices?.totalFirstDownloadsIos ?? 0 }} </p>
            </td>
          </tr>
          <tr>
            <td>
              <h4 class="text-truncate fs-14 fs-medium mb-0">
                Primeras Descargas Sistema Operativo Android
              </h4>
            </td>
            <td>
              <p class="text-muted mb-0"> {{ devices?.totalFirstDownloadsAndroid ?? 0 }} </p>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </b-card-body>
  </b-card>
</template>
