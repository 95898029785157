import gql from 'graphql-tag'

export const DASHBOARD_APPS = gql`
    query($input: DashboardAnalyticsInput!) {
        dashboardAnalytics(input: $input) {
            chart
            tableUseApplication
            amountPerUse
        }
    }
`



